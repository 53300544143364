async function fetchAPI(query, { variables } = {}) {
  const res = await fetch(
    `${process.env.REACT_APP_API_DOMEN}${process.env.REACT_APP_API_URL}?apikey=${process.env.REACT_APP_API_KEY}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query, variables }),
    }
  )
  const json = await res.json()

  if (json.errors) {
    console.error(json.errors)
    throw new Error('Failed to fetch API')
  }
  return json.data
}

export async function getProductMainInfo(itemnumber) {
  const data = await fetchAPI(
    `
  query ProductMainInfo($defaultLanguage: String, $filter: String) {
    getProductListing(defaultLanguage: $defaultLanguage, filter: $filter) {
      edges {
        node {
          id
          code
          itemnumber
          mesureUnit {
            ... on object_MeasureUnit {
              id
              name
              code
            }
          }
          name
          productname
          fullname
          nameForThePricetag
          classificator {
            ... on object_ProductClassificator {
              id
              code
              name
              name1
              name2
              name3
            }
          }
          country {
            ... on object_ProductGeography {
              id
              code
              parentCode
              name
            }
          }
          region {
            ... on object_ProductGeography {
              id
              code
              parentCode
              name
            }
          }
          placeorigin {
            ... on object_ProductGeography {
              id
              code
              parentCode
              name
            }
          }
          manufacturer {
            ... on object_productManufacturer {
              id
              code
              name
              country
            }
          }
        }
      }
    }
  }
  `,
    {
      variables: {
        defaultLanguage: "uk",
        filter: `{"itemnumber": {"$like": "%${itemnumber}%"}}`,
      }
    }
  )
  return data.getProductListing.edges.length && data.getProductListing.edges[0].node
}

export async function getAlcoholAndNutrition(code) {
  const data = await fetchAPI(
    `
  query ProductAlcoholAndNutritionInfo($defaultLanguage: String, $filter: String) {
    getProductListing(defaultLanguage: $defaultLanguage, filter: $filter) {
      edges {
        node {
          id
          code
          itemnumber
          year
          size
          alcohol
          sugar
          aging
          varietalshare
          vintage
          orange
          naturalwine
          sulfite
          calories
          fat
          fatcontent
          protein
          carb
          sugar
          classificationwithincountries {
            ... on object_WineCountryClass {
              id
              ref
              description
              classification
            }
          }
          raiting {
            ... on object_WineJoin {
              id
              ref
              description
            }
          }
        }
      }
    }
  }
  `,
    {
      variables: {
        defaultLanguage: "uk",
        filter: `{"code": {"$like": "%${code}%"}}`,
      }
    }
  )
  return data.getProductListing.edges.length && data.getProductListing.edges[0].node
}

export async function getFoodAndNutrition(code) {
  const data = await fetchAPI(
    `
  query ProductFoodAndNutritionInfo($defaultLanguage: String, $filter: String) {
    getProductListing(defaultLanguage: $defaultLanguage, filter: $filter) {
      edges {
        node {
          id
          code
          itemnumber
          farm
          glutenfree
          halal
          vegan
          lactosefree
          coimpositeproduct
          kosher
          sugarless
          vegetarian
          vert_cert
          fao_select
          allegren
          calories
          fat
          fatcontent
          protein
          carb
          sugar
        }
      }
    }
  }
  `,
    {
      variables: {
        defaultLanguage: "uk",
        filter: `{"code": {"$like": "%${code}%"}}`,
      }
    }
  )
  return data.getProductListing.edges.length && data.getProductListing.edges[0].node
}

export async function getDescriptionByProduct(product) {
  const data = await fetchAPI(
    `
  query DescriptionByProduct($defaultLanguage: String, $filter: String) {
    getProductDescriptionListing(defaultLanguage: $defaultLanguage, filter: $filter) {
      edges {
        node {
          id
          product
          descriptionType {
            ... on object_ProductDescriptionType {
              id
              code
              del_mark
              description
            }
          }
          desription
        }
      }
    }
  }
  `,
    {
      variables: {
        defaultLanguage: "uk",
        filter: `{"product": "${product}"}`,
      }
    }
  )
  return data.getProductDescriptionListing.edges.length && data.getProductDescriptionListing.edges[0].node
}

export async function getPirturesByProduct(product) {
  const data = await fetchAPI(
    `
  query PirturesByProduct($defaultLanguage: String, $filter: String) {
    getProductPicturesListing(defaultLanguage: $defaultLanguage, filter: $filter) {
      edges {
        node {
          id
          product
          linkJpg
          linkPng
        }
      }
    }
  }
  `,
    {
      variables: {
        defaultLanguage: "uk",
        filter: `{"product": "${product}"}`,
      }
    }
  )
  return data.getProductPicturesListing.edges.length && data.getProductPicturesListing.edges[0].node
}

export async function getPriceByProduct(product) {
  const data = await fetchAPI(
    `
    query PriceByProduct($defaultLanguage: String, $filter: String) {
      getActualPricesListing(defaultLanguage: $defaultLanguage, filter: $filter) {
        edges {
          node {
            id
            product
            price
            priceCategory
            code
            AMeasureUnits {
              ... on object_AMeasureUnits {
                id
                ref
                owner
                deletionMark
                description
                volume
                coefficient
              }
            }
          }
        }
      }
    }
    `,
    {
      variables: {
        defaultLanguage: "uk",
        filter: `{"product": "${product}"}`
      }
    }
  )
  return data.getActualPricesListing.edges.length && data.getActualPricesListing.edges[0].node
}

export async function getListByName(name) {
  const data = await fetchAPI(
    `
  query ListByName($defaultLanguage: String, $first: Int!, $sortBy: [String], $filter: String) {
    getProductListing(defaultLanguage: $defaultLanguage, first: $first, sortBy: $sortBy, filter: $filter) {
      edges {
        node {
          id
          itemnumber
          code
          name
          classificator {
            ... on object_ProductClassificator {
              id,
              code
              name
              name1
              name2
              name3
            }
          }
        }
      }
    }
  }
  `,
    {
      variables: {
        defaultLanguage: "uk",
        first: 5,
        sortBy: "itemnumber",
        filter: `{"name": {"$like": "%${name}%"}}`,
      }
    }
  )
  return data.getProductListing.edges
}

export async function getProductByBarcode(barcode) {
  const data = await fetchAPI(
    `
    query ProductByBarcode($defaultLanguage: String, $filter: String) {
      getProductBarcodeListing(defaultLanguage: $defaultLanguage, filter: $filter) {
        edges {
          node {
            id,
            barcode,
            product,
            barcode_main,
            barcode_type,
            barcode_notForSale
          }
        }
      }
    }
    `,
    {
      variables: {
        defaultLanguage: "uk",
        filter: `{"barcode": {"$like": "%${barcode}%"}}`
      }
    }
  )
  return data.getProductBarcodeListing.edges
}

export async function getSkyByProduct(code) {
  const data = await fetchAPI(
    `
  query SkyByProduct($defaultLanguage: String, $filter: String) {
    getProductListing(defaultLanguage: $defaultLanguage, filter: $filter) {
      edges {
        node {
          id
          code
          itemnumber
        }
      }
    }
  }
  `,
    {
      variables: {
        defaultLanguage: "uk",
        filter: `{"code": {"$like": "%${code}%"}}`,
      }
    }
  )
  return data.getProductListing.edges.length && data.getProductListing.edges[0].node.itemnumber
}
