import { useState } from 'react'
import { Elevation } from '@rmwc/elevation'
import { SimpleDialog } from '@rmwc/dialog'
import { ProductClose } from '../product-close/product-close'
import classes from './product-image.module.css'
import './product-image.css'

export const ProductImage = ({ linkJpg, linkPng, name, min, horizontal }) => {
  const [elevation, setElevation] = useState(2)
  const [renderToPortal, setRenderToPortal] = useState(true)
  const [open, setOpen] = useState(false)

  if (linkJpg || linkPng) {
    return (
      <div className={classes.image}>
        <SimpleDialog
          renderToPortal={renderToPortal}
          body={<img src={linkJpg || linkPng} alt={name} style={{
            maxHeight: '80vh',
            maxWidth: '400px',
            height: 'auto',
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
          />}
          open={open}
          onClose={evt => {
            console.log(evt.detail.action)
            setOpen(false)
          }}
          acceptLabel={null}
          cancelLabel={<ProductClose />}
        />
        <Elevation
          z={elevation}
          transition
          onMouseOver={() => setElevation(8)}
          onMouseOut={() => setElevation(2)}
          className={classes.elevation}
          onClick={() => {
            setRenderToPortal(true)
            setOpen(true)
          }}
        >
          <img
            src={linkJpg || linkPng}
            alt={name}
            style={{maxHeight: min}} className={classes.img}
          />
        </Elevation>
      </div>
    )
  } else return null
}
