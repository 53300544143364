import { useState } from 'react'
import { TabBar, Tab } from '@rmwc/tabs'
import { ProductList } from '../product-list/product-list'
import './product-description.css'
import classes from './product-description.module.css'

export const ProductDescription = ({ product, horizontal }) => {
  const [activeTab, setActiveTab] = useState(0)
  const tabs = ['Опис', 'Додатково']

  if (Object.keys(product).length) {
    if (!product.desription) {
      const idx = tabs.findIndex(t => t === 'Опис')
      tabs.splice(idx, 1)
    }

    if (!product.protein && !product.fat && !product.fatcontent && !product.carb && !product.sugar && !product.sulfide) {
      const idx = tabs.findIndex(t => t === 'Додатково')
      tabs.splice(idx, 1)
    }
  }

  return (
    <div className={horizontal ? `${classes.description} ${classes.horizontal}` : classes.description}>
      <TabBar activeTabIndex={activeTab} onActivate={(evt) => setActiveTab(evt.detail.index)}>
        {tabs.map(tab =>
          <Tab key={tab} className={horizontal ? 'horizontal' : null}>
            {tab}
          </Tab>)
        }
      </TabBar>

      <div>
        {
          product.desription && tabs[activeTab] === 'Опис'
            ? <p className={horizontal ? `${classes.paragraph} ${classes.horizontal}` : classes.paragraph}>{product.desription}</p>
            : null
        }
        {
          (product.protein || product.fat || product.fatcontent || product.carb || product.sugar || product.sulfide) && (tabs[activeTab] === 'Додатково')
            ? <ProductList product={product} horizontal={horizontal} />
            : null
        }
      </div>
    </div>
  )
}