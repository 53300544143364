import classes from './product-list.module.css'

export const ProductList = ({ product, horizontal }) => {
  if (product.isAlcohol) {
    return (
      <div className={horizontal ? `${classes.list} ${classes.horizontal}` : classes.list}>
        <p><b>Поживна цінність - {product.calories} Ккал;</b></p>
        <p>В тому числі:</p>
        <ul>
          <li>Білки - 0</li>
          <li>Жири – 0</li>
          <li>Насищенні жири - 0</li>
          <li>Вуглеводи – {product.carb ? product.carb : 'н.д.'}</li>
          <li>Цукор - {product.sugar ? product.sugar : 'н.д.'}</li>
          <li>Містить сульфіди – {product.sulfite ? product.sulfite : 'н.д.'}</li>
        </ul>
      </div>
    )
  } else {
    return (
      <div className={horizontal ? `${classes.list} ${classes.horizontal}` : classes.list}>
        <p><b>Поживна цінність - {product.calories} Ккал;</b></p>
        <p>В тому числі:</p>
        <ul>
          <li>Білки - {product.protein ? product.protein : 'н.д.'}</li>
          <li>Жири - {product.fat ? product.fat : 'н.д.'}</li>
          <li>Насищенні жири - {product.fatcontent ? product.fatcontent : 'н.д.'}</li>
          <li>Вуглеводи – {product.carb ? product.carb : 'н.д.'}</li>
          <li>Цукор – {product.sugar ? product.sugar : 'н.д.'}</li>
        </ul>
      </div>
    )
  }
}
