import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import './index.css'
import 'rmwc/dist/styles'
import { ThemeProvider } from '@rmwc/theme'
import { Routes } from './routes'

ReactDOM.render(
  <>
    <ThemeProvider options={{
      primary: '#F9AF6B',
      secondary: 'black',
      onPrimary: '#000',
      textPrimaryOnBackground: 'black',
    }}>
      <Routes />
    </ThemeProvider>
  </>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
