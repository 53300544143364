import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import { Home } from './pages/home/home'
import { Product } from './pages/product/product'

import ReactGA from 'react-ga'
import GA from './routeChangeTracker'

const TRACKING_ID = 'UA-194941599-1'
ReactGA.initialize(TRACKING_ID)

export const Routes = () => (
  <Router basename={'/'}>
    <Switch>
      <Route component={Home} path={'/'} exact />
      <Route component={Product} path={'/:code'} />
    </Switch>
    <GA />
  </Router>
)
