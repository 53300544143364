import { useHistory } from 'react-router-dom'
import classes from './product-back.module.css'

export const ProductBack = () => {
  const history = useHistory()
  return (
    <div className={classes.button} onClick={() => history.push('/')}>
      <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.7471 7.0548H4.67383L10.308 2.82916C10.7104 2.42672 10.9116 1.82303 10.5092 1.42059C10.1068 1.01815 9.50308 0.81692 9.10064 1.21936L1.05183 7.25597C0.64939 7.65841 0.64939 8.26209 1.05183 8.66454L9.10064 14.7011C9.50308 15.1036 10.1068 14.9024 10.5092 14.4999C10.9116 14.0975 10.7104 13.4938 10.308 13.0914L4.67383 8.86577H16.7471C22.3812 8.86577 26.8081 13.2926 26.8081 18.9268C26.8081 24.5609 22.3812 28.9878 16.7471 28.9878H13.7287C13.1251 28.9878 12.7226 29.3902 12.7226 29.9939C12.7226 30.5976 13.1251 31 13.7287 31H16.7471C23.3873 31 28.8203 25.5671 28.8203 18.9268C28.8203 12.4877 23.5885 7.0548 16.7471 7.0548Z" fill="#7B7B7B" stroke="#7B7B7B"/>
      </svg>
    </div>
  )
}