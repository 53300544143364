import { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getProductMainInfo, getAlcoholAndNutrition, getFoodAndNutrition, getDescriptionByProduct, getPirturesByProduct, getPriceByProduct } from '../../utils/api'
import { useWindowSize } from '../../hooks/size.hook'
import { ProductDescription } from '../../components/product-description/product-description'
import { ProductProps } from '../../components/product-props/product-props'
import { ProductImage } from '../../components/product-image/product-image'
import { ProductBack } from '../../components/product-back/product-back'
import { ProductPrice } from '../../components/product-price/product-price'
import { LinearProgress } from '@rmwc/linear-progress'
import classes from './product.module.css'

export const Product = () => {
  const [product, setProduct] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)
  const [minHeight, setMinHeight] = useState(0)
  const history = useHistory()
  const ref = useRef()
  const { width, height, horizontal } = useWindowSize()
  const { code } = useParams()

  useEffect(() => {
    async function fetchData() {
      setIsLoaded(false)
      try {
        const response = await getProductMainInfo(code)
        const description = await getDescriptionByProduct(response.code)
        const pictures = await getPirturesByProduct(response.code)
        const price = await getPriceByProduct(response.code)
        const geography = [...new Set([response.country?.name, response.region?.name, response.placeorigin?.name])].filter(i => i).join(', ')

        if (response.classificator.name1.toLowerCase().includes('алко')) {
          const alcohol = await getAlcoholAndNutrition(response.code)
          setIsLoaded(true)
          return setProduct({ ...response, ...description, ...pictures, ...price, ...alcohol, geography, isAlcohol: true })
        } else {
          const food = await getFoodAndNutrition(response.code)
          setIsLoaded(true)
          return setProduct({ ...response, ...description, ...pictures, ...price, ...food, geography, isAlcohol: false })
        }
      } catch (e) {
        console.log(e)
        setIsLoaded(true)
        history.push('/')
      }
    }
    fetchData()
  }, [code, history])

  useEffect(() => {
    if (isLoaded && !!Object.keys(product)) {
      return setMinHeight(ref.current?.children[0].clientHeight)
    }
  }, [ref, isLoaded, product, width, height])

  if (!isLoaded) {
    return <LinearProgress />
  } else {
    return (
      <div className={horizontal ? `${classes.product} ${classes.flex}` : `${classes.product} ${classes.grid}`}>
        <section>
          <ProductPrice
            mesureUnit={product.mesureUnit}
            price={product.price}
          />
          <ProductBack />
          <ProductImage
            linkPng={product.linkPng}
            linkJpg={product.linkJpg}
            name={product.name}
            min={minHeight}
            horizonal={horizontal}
          />
        </section>
        <section ref={ref}>
          <ProductProps product={product} horizontal={horizontal} />
        </section>
        <section>
          <ProductDescription product={product} horizontal={horizontal} />
        </section>
      </div>
    )
  }
}
