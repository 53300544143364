import classes from './product-props.module.css'

export const ProductProps = ({ product, horizontal }) => {
  const list = []

  if (product.size) {
    list.push({ label: 'ємкість', value: product.size })
  }
  if (product.sugar) {
    list.push({ label: 'цукор', value: product.sugar })
  }
  if (product.alcohol) {
    list.push({ label: 'міцність', value: product.alcohol + '%' })
  }
  if (product.varietalshare && product.varietalshare.length) {
    list.push({ label: 'сортовий склад', value: product.varietalshare })
  }
  if (product.classificationwithincountries && product.classificationwithincountries.length) {
    list.push({ label: 'класифікація', value: product.classificationwithincountries[0].description })
  }
  if (product.manufacturer && product.manufacturer.name) {
    list.push({ label: 'виробник', value: product.manufacturer.name })
  }
  if (product.geography && product.geography.length) {
    list.push({ label: 'географiя', value: product.geography })
  }
  if (product.calories) {
    list.push({ label: 'Калорійність', value: product.calories })
  }

  return (
    <div className={horizontal ? `${classes.horizontal} ${classes.props}` : classes.props}>
      <div>Арт. {product.itemnumber}</div>
      <div>{product.name ? product.name : 'No name'}</div>
      <div>
        {list.map(l => (
          <div key={l.label}>
            <div>{l.label}</div>
            <div>{l.value}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
